@import 'mixins';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import 'layer_base';
}

@layer components {
    @import 'layer_components';
}

@layer utilities {
    @import 'layer_utilities';
}
