html {
    font-size: 6px;
}

input {
    @apply placeholder-gray-darker;

    &[type=file]::file-selector-button {
        @apply hidden;
    }
}

@screen sm {
    html {
        font-size: 8px;
    }
}

@screen md {
    html {
        font-size: 10px;
    }
}

object {
    svg {
        @apply fill-current;
    }
}

p {
    @apply mb-8;
}
