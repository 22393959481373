@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?x42ysn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?x42ysn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?x42ysn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-navicon {
  &:before {
    content: $icon-navicon; 
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder; 
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left; 
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right; 
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up; 
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-messenger {
  &:before {
    content: $icon-messenger; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}

