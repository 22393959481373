@import './icomoon/style.scss';

.fade-item {
    transition-property: opacity, position;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    position: absolute;
}

::marker {
    @apply text-green;
}

.text-shadow {
    text-shadow: 0 0 2px #000;
}
