.dropdown {
    > .dropdown-menu {
        @apply invisible fixed left-0 w-full lg:absolute lg:right-0 lg:mx-auto flex flex-col justify-start z-20;
        @apply transform-gpu transition-all duration-300 opacity-0 -translate-y-8 -translate-x-4 scale-90;
        @apply overflow-visible;
    }

    @include hover-supported() {
        @screen lg {
            &:hover {
                > a {
                    .icon-chevron-down {
                        @apply rotate-180;
                    }
                }

                > .dropdown-menu {
                    @apply visible opacity-100 scale-100 translate-y-0 translate-x-0;
                }

                .dropdown {
                    &:hover {
                        > .dropdown-menu {
                            @apply relative shadow-inner;
                        }
                    }
                }
            }
        }
    }

    &.active {
        > a {
            .icon-chevron-down {
                @apply rotate-180;
            }
        }

        > .dropdown-menu {
            @apply visible opacity-100 scale-100 translate-y-0 translate-x-0;
        }

        .dropdown {
            &.active {
                > .dropdown-menu {
                    @apply relative shadow-inner;
                }
            }
        }
    }
}

.index-field-block {
    background-image: url('../images/field.jpg');

    @apply bg-no-repeat bg-cover bg-center;

    @screen 2xl {
        background-image: url('../images/field@2x.jpg');
    }

    &::before {
        @apply block absolute top-0 left-0 w-full h-full z-0 bg-filter;

        content: " ";
    }
}

.splide {
    .splide__pagination {
        @apply gap-2 absolute lg:relative w-full bottom-12 lg:bottom-auto;

        .splide__pagination__page {
            @apply w-5 h-5 border-2 border-green rounded-full transform-gpu transition-colors duration-500 mb-px;

            &.is-active {
                @apply bg-green;
            }
        }

        li {
            @apply leading-normal;
        }
    }

    .splide__slider {
        .splide__track {
            @apply overflow-y-visible;

            overflow-x: clip;
        }
    }

    &.thumbnails {
        .splide__slider {
            .splide__track {
                @apply z-5;

                max-width: 75%;

                .splide__list {
                    .splide__slide {
                        &.is-active {
                            @apply border-green-contact;
                        }
                    }
                }
            }
        }
    }
}

.blog-body {
    figure.video {
        position: relative;
        overflow: hidden;
        width: 100%;

        &::after {
            display: block;
            content: "";
            padding-top: 56.25%;
        }

        > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.page {
    h3 {
        @apply text-green text-40 my-8;
    }

    h4 {
        @apply text-green text-30 my-4;
    }
}

.map-responsive {
    @apply overflow-hidden relative h-0;

    // padding-bottom: 56.25%;
    padding-bottom: 80%;

    iframe {
        @apply left-0 top-0 h-full w-full absolute;
    }
}
